






















































































































































































































































































































































































































































import Vue from 'vue';
import _ from 'lodash';
import xss from 'xss';
import { ElForm } from 'element-ui/types/form';
import Invoice from './component/invoice.vue';
import { ResponseCode } from '~/util/constant';
import { $cookies } from '~/util/api';
import { getContent, getXOssProcess, message } from '~/util/common';
// import { serviceJournal } from '~/pages/fair/service';
import { TraceCode } from '~/util/trace';

export default Vue.extend({
  name: 'Periodical',
  components: {
    Invoice,
  },
  filters: {
    hasPhoneOrEmail(containPhoneEmail: string): string {
      if (!containPhoneEmail) return '';
      const val: string[] = [];
      // const { containPhoneEmail = [] } = this.newPeriodical;
      if (containPhoneEmail.includes('0')) {
        val.push('电话');
      }
      if (containPhoneEmail.includes('1')) {
        val.push('邮箱');
      }
      if (containPhoneEmail.includes('2')) {
        val.push('官网');
      }
      return val.length > 0 ? `含${val.join('、')}` : '';
    },
  },
  async asyncData(ctx) {
    const lang = _.get(ctx, 'query.lang', null);
    let langApi = 'cn';
    if (lang == 'zh') langApi = 'cn';
    if (lang == 'en') langApi = 'en';
    const { periodicalId } = ctx.query;
    const res: any[] = await Promise.all([
      ctx.$axios.get(`/api/exhibition/${ctx.params.id}`),
      ctx.$axios.get(`/api/periodical/periodicalList/${ctx.params.id}`),
    ]);
    const [fairDetailRes, periodicalRes] = res;

    let fairDetail: Record<string, any> = {};
    const industry: any[] = [];
    let industryId: any[] = [];
    let industryName: any[] = [];
    if (fairDetailRes.code === ResponseCode.SUCCESS) {
      if (!fairDetailRes.data) {
        return ctx.error({
          message: '展会不存在!',
          path: ctx.route.fullPath,
          statusCode: 404,
        });
      }

      fairDetail = fairDetailRes.data || {};
      console.log('381-', fairDetail);
      // online 1:上架 2:未上架
      if (!_.isNil(fairDetail.online) && fairDetail.online !== 1) {
        return ctx.error({
          message: 'Page not found',
          path: ctx.route.fullPath,
          statusCode: 404,
        });
      }

      industryId = [
        fairDetail.industry1,
        fairDetail.industry2,
        fairDetail.industry3,
      ].filter((id) => !!id);
      industryName = fairDetail.industry?.split(',') || [];
      industryName?.forEach((name, index) => {
        industry.push({
          id: industryId[index],
          name,
        });
      });
    }

    const otherRes: any[] = await Promise.all([
      ctx.$axios.post('/api/exhibition/list', {
        // locale: langApi,
        selectTimeType: 1,
        industryId: _.get(industry, '[0].id', null), // 行业展会
        regionType: fairDetail.regionType,
        pageNum: 1,
        pageSize: 6,
      }),
    ]);

    const [industryFairRes] = otherRes;

    // 行业展会
    let industryFair: any = {};
    let industryFairList: any = [];
    if (industryFairRes.code === ResponseCode.SUCCESS) {
      industryFair = industryFairRes.data;
      industryFairList = industryFairRes.data.records;
      console.log('503-行业展会', industryFair);
    }

    if (industryFair) {
      let pavilionIdList: any[] = industryFair.records.map(
        (item: any) => item.pavilionId
      );
      console.log('514-', pavilionIdList);
      const pavilionRes: any[] = await Promise.all([
        ctx.$axios.post('/api/pavilionApi/list', {
          pavilionIdList: pavilionIdList, // 根据展馆id查询展馆信息
        }),
      ]);

      const [pavilionListRes] = pavilionRes;

      // 展馆列表
      console.log('529-', pavilionListRes.data.records);
      if (pavilionListRes.code === ResponseCode.SUCCESS) {
        industryFairList.map((item: any) => {
          pavilionListRes.data.records.map((pavilion: any) => {
            if (item.pavilionId === pavilion.id) {
              item['pavilionName'] = pavilion.pavilionName;
            }
          });
        });
        console.log('538-', industryFairList);
      }
    }
    //

    let periodicalList = [];
    if (periodicalRes.code === ResponseCode.SUCCESS) {
      periodicalList = periodicalRes.data;
      console.log('367-367', periodicalList);
      periodicalList.map((item: any) => {
        item['checked'] = false;
        item['tag1'] = '';
        item['tag2'] = '';
        item['tag3'] = '';
        item['image'] =
          item.snapshotList && item.snapshotList.length > 0
            ? item.snapshotList[0].url
            : '';
        if (item.containPhoneEmail && item.containPhoneEmail?.includes('0'))
          item['tag1'] = '电话';
        if (item.containPhoneEmail && item.containPhoneEmail?.includes('1'))
          item['tag2'] = '邮箱';
        if (item.containPhoneEmail && item.containPhoneEmail?.includes('2'))
          item['tag3'] = '官网';
      });
    }

    const fairRes: any = await ctx.$axios.post('/api/exhibition/list', {
      locale: langApi,
      selectTimeType: 1,
      industryId: _.get(industry, '[0].id', null), // 行业展会
      regionType: fairDetail.regionType,
      pageNum: 1,
      pageSize: 6,
    });
    let fair = {};
    if (fairRes.code === ResponseCode.SUCCESS) {
      fair = fairRes.data;
    }

    return {
      fairDetail,
      fairId: ctx.params.id,
      fair,
      ruleForm: {
        periodicalId,
      },
      periodicalId,
      periodicalList,
      periodical: periodicalList.find((item: any) => item.id === +periodicalId),
      industryFair,
      industryFairList,
    };
  },
  data(): Record<string, any> {
    return {
      period: {},
      fairDetail: {},
      rules: {
        contactName: [
          { required: true, message: '请输入联系人', trigger: 'blur' },
        ],
        concatTel: [
          { required: true, message: '请输入联系人电话', trigger: 'blur' },
        ],
        concatMail: [
          { required: true, message: '请输入联系人邮箱', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' },
        ],
      },
      ruleForm: {
        periodicalId: 0,
      },
      periodicalList: [],
      periodical: {},
      countPrice: '0.00',
      submitDisabled: false,
      service: [],
      isSdkReady: false, // 客戶sdk初始化判断
      serviceList: [],
      showPreviewImage: false,
      previewImage: '',
    };
  },
  watch: {
    ruleForm: {
      handler(value) {
        let periodical: any = this.periodical;
        if (value.periodicalId) {
          periodical = this.periodicalList.find(
            (item: any) => item.id === +value.periodicalId
          );
          this.periodical = periodical;
        }
        this.countPrice = _.get(periodical, 'price', '0.00');
      },
      deep: true,
    },
  },
  computed: {
    photos(): any[] {
      try {
        const newArr: any[] = [];
        if (this.fairDetail.photos) {
          this.fairDetail.photos
            .slice(0, 6)
            .map((item: { resourceUrl: any }) => {
              newArr.push(item.resourceUrl);
            });
        }
        return newArr;
      } catch (error) {
        if (process.client && 'fundebug' in window) {
          window.fundebug.notifyError(error);
        }
        return this.fairDetail.pic;
      }
    },
  },
  mounted() {
    this.getService();
  },
  created() {
    if (process.client) {
      const ruleForm = sessionStorage.getItem('periodOrderForm') || '{}';
      try {
        this.ruleForm = JSON.parse(ruleForm);
        if (this.periodicalId) {
          this.$set(this.ruleForm, 'periodicalId', this.periodicalId);
        }
        sessionStorage.removeItem('periodOrderForm');
      } catch (error) {}
    }
    const phone = this.$cookies.get('phoneNumber') || '';
    this.$set(this.ruleForm, 'concatTel', `${phone}`);
    // this.fetchService();
  },
  methods: {
    xss,
    getContent,
    getXOssProcess,
    handlePreview(e: string, id: number) {
      // window.open(e);
      // this.$preview
      // this.previewImage = e;
      // this.showPreviewImage = true;
      // return false;
      // let r: any = 'previewImg_' + id;
      // console.log(704, this.$refs[r][0]);
      // console.dir(this.$refs[r][0]);
      // this.$refs[r][0].click();
    },
    init() {
      window.ysf('onready', () => {
        this.isSdkReady = true;
      });
    },
    handleDetail(id: number) {
      window.open(`/periodical/${id}.html`);
    },
    // 获取客服
    async getService() {
      console.log(2139, this.fairDetail);

      const res: any = await this.$axios.post(`/api/hr/staff/services`, {
        exhibitionId: this.fairDetail.exhibitionId,
        industry: this.fairDetail.industry1,
        skillCodes: ['periodical'],
      });
      if (res.code === ResponseCode.SUCCESS) {
        console.log('获取客服', res);
        let list = res.data;
        list.map((item: any) => {
          item.avatar = `${item.avatar}?x-oss-process=image/auto-orient,1/interlace,1/resize,m_fill,w_70,h_70/sharpen,100`;
        });
        this.serviceList = res.data || [];

        // let count:number = 0
        // if(!res.data) return false
        // for(let i in this.rateData){
        //   this.rateData[i] = res.data[i]
        //   count+=res.data[i]
        // }
        // this.rateValue = Number((count / 5).toFixed(1))
        // console.log(2667,this.rateData,this.rateValue)
      }
    },
    async fetchService() {
      const res = await this.$axios.get(
        `https://dcdn-jufair.jufair.com/service/service.json`
      );

      if (res) {
        this.service =
          res.serviceJournal?.service?.map((n: string) => res[n]) || [];
      }
    },
    // 点击打开聊天窗
    openkefu(chat: string) {
      if (chat) {
        if (this.isSdkReady) {
          window.ysf('open');
        }
      }
    },
    handleItemClick(id: number) {
      this.periodicalList.map((item: any) => {
        item.checked = false;
        if (item.id === id) {
          item.checked = true;
        }
      });
      this.$set(this.ruleForm, 'periodicalId', id);
    },
    checkboxChange(e: any, id: any) {
      console.log(523, e);
      this.periodicalList.map((item: any) => {
        item.checked = false;
        if (item.id === id) {
          if (e) {
            item.checked = false;
            this.$set(this.ruleForm, 'periodicalId', null);
          } else {
            item.checked = true;
          }
        }
      });
      this.$set(this.ruleForm, 'periodicalId', id);
    },
    shouldLogin(): boolean {
      const access_token = $cookies.get('access_token');
      // 获取不到token为未登录, 前往登录页
      if (!access_token) {
        this.$login.show();
        // this.$router.push(`/login?redirect=${encodeURIComponent(this.$route.fullPath)}`);
        sessionStorage.setItem(
          'periodOrderForm',
          JSON.stringify(this.ruleForm)
        );
      }
      return !!access_token;
    },
    async submitData() {
      const { userId } = this.$store.state.global.user;
      // const $invoice: any = this.$refs.invoice;
      const { exhibitionName } = this.$data.fairDetail;
      const { fairId } = this.$data;
      const { year, name, price, id } = this.periodical as any;

      const params: Record<string, any> = {
        ...this.ruleForm,
        exhibitionId: fairId,
        exhibitionName,
        userId,
        periodicalName: name,
        periodicalYear: year,
        totalAmount: price.toString(),
        osType: '2', // 1:h5  2:PC  3:小程序
        payType: '1',
        // isKp: $invoice.isKp ? '1' : '0',
        periodicalId: this.ruleForm.periodicalId.toString(),
      };

      // if ($invoice.isKp) {
      //   params.invoice = {
      //     ...$invoice.invoiceRuleForm,
      //   };
      // }
      await this.$store.commit('order/setPeriodical', params);
      this.$router.push(`/payment/?id=${fairId}&periodicalId=${id}&mode=pdf`);
      // 埋点
      window.trace?.upload({
        module: TraceCode.MO_FAIR_PERIODICAL,
        callbackCode: TraceCode.CC_SUBMIT_PERIODICAL,
        bizId: this.ruleForm.periodicalId,
        bizOrderPayType: TraceCode.PAY,
      });
    },
    async handleSubmit() {
      const $orderform = this.$refs.orderform as ElForm;
      const isLogin = this.$cookies.get('access_token');
      if (!isLogin) {
        await this.$store.commit(
          'user/setOrigins',
          location.origin + this.$route.fullPath
        );
        await this.$store.commit('user/setAction', 3);
        await this.$store.commit(
          'user/setIndustryId',
          this.fairDetail.industry1
        );

        this.toLogin();
        return;
      }
      $orderform.validate((valid) => {
        if (valid) {
          const $invoice: any = this.$refs.invoice;
          if (!this.ruleForm.periodicalId && this.periodicalList.length > 0) {
            return this.$message.warning('请选择要购买的期刊.');
          }
          // if ($invoice.isKp) {
          //   const $invoiceForm = $invoice.$refs.invoiceform as ElForm;
          //   $invoiceForm.validate((valid2) => {
          //     if (valid2 && isLogin) {
          //       if (this.periodicalList.length > 0) {
          //         this.submitData();
          //       } else {
          //         this.submitApplyData();
          //       }
          //     }
          //   });
          // } else
          if (isLogin) {
            if (this.periodicalList.length > 0) {
              this.submitData();
            } else {
              this.submitApplyData();
            }
          }
        }
      });
    },
    async showMessage() {
      const { exhibitionName } = this.$data.fairDetail;

      await message.call(this, {
        delay: 3000,
        type: 'warning',
        title: '温馨提示',
        msg: `尊敬的用户，您已订购${exhibitionName}电子会刊，我们将在第一时间通知您最新的会刊信息。`,
      });
    },
    async queryInfo() {
      const { userId } = this.$store.state.global.user;

      const params = {
        exhibitionId: this.$data.fairId,
        userId,
      };
      const res: any = await this.$axios.post(
        '/api/periodical/orderInfoQuery',
        params
      );

      if (res.code === ResponseCode.SUCCESS) {
        if (res.data === '已申请') {
          this.submitDisabled = true;
          this.showMessage();
        }
      }
    },
    toLogin() {
      // const { fullPath } = this.$route;
      this.$login.show();
      // this.$router.push({
      //   path: `/login?redirect=${encodeURIComponent(fullPath)}`,
      // });
    },
    async submitApplyData() {
      const token = this.$cookies.get('access_token');
      if (!token) {
        this.toLogin();
        return;
      }
      const { userId } = this.$store.state.global.user;
      const { exhibitionName } = this.$data.fairDetail;
      await this.queryInfo();
      if (this.submitDisabled) {
        return;
      }

      const params: Record<string, any> = {
        ...this.ruleForm,
        exhibitionId: this.$data.fairId,
        exhibitionName,
        userId,
        osType: '2', // 1:h5  2:PC  3:小程序
        periodicalId: undefined,
      };

      this.submitDisabled = true;
      const res: any = await this.$axios.post(
        '/api/periodical/applyFor',
        params
      );

      if (res.code === ResponseCode.SUCCESS) {
        const $orderform = this.$refs.orderform as ElForm;
        const { fairDetail } = this.$data;

        $orderform?.resetFields();
        await message.call(this, {
          delay: 3000,
          type: 'success',
          title: '会刊申请成功',
          msg: `尊敬的用户，您订购的${fairDetail.exhibitionName}电子会刊还未更新，我们将在第一时间通知您最新的会刊信息。`,
        });
        // 埋点
        window.trace?.upload({
          module: TraceCode.MO_FAIR_PERIODICAL,
          callbackCode: TraceCode.CC_SUBMIT_PERIODICAL,
          bizId: this.ruleForm.periodicalId,
          bizOrderPayType: TraceCode.FREE,
          success: TraceCode.SUCCESS,
        });
      } else {
        this.submitDisabled = false;
        await message.call(this, {
          delay: 3000,
          type: 'error',
          title: '会刊申请失败',
          msg: '请联系客服处理。',
        });
        // 埋点
        window.trace?.upload({
          module: TraceCode.MO_FAIR_PERIODICAL,
          callbackCode: TraceCode.CC_SUBMIT_PERIODICAL,
          bizId: this.ruleForm.periodicalId,
          bizOrderPayType: TraceCode.FREE,
          success: TraceCode.FAIL,
        });
      }
    },
  },
  head(): Record<string, any> {
    const { fairDetail } = this.$data;
    const { exhibitionName } = fairDetail;
    const year = new Date().getFullYear();
    return {
      title: `${exhibitionName}参展商参展企业有哪些？参展商名单_参展商名录_电子会刊-聚展`,
      meta: [
        {
          name: 'keywords',
          content: `${exhibitionName}参展企业有哪些？${exhibitionName}参展企业名单,名录,会刊订阅`,
        },
        {
          name: 'description',
          content: `${year}年${exhibitionName}电子会刊订阅,展商名录订阅`,
        },
      ],
    };
  },
  // 校验params参数
  validate(ctx) {
    const id = _.get(ctx, 'params.id', null);
    return /^\d+$/.test(id);
  },
});
